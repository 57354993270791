<template>
  <div class="home">
    <!--    大屏-->
    <div class="box">
      <div class="logotitle-box">
        <img class="logotitle" src="../assets/logotitle.png">
        <button @mousemove="appear = 1" @mouseout="appear = null">登录平台 <img src="../assets/right_arrow.png" />
          <div class="QR-code-box-one" v-show="appear ==1">
            <img src="../assets/QR-code.png">
            <p>扫码打开</p>
          </div>
        </button>
      </div>
    </div>
    <div class="logo">
      <div class="logo-box">
        <div class="logo-left">
          <p>专业改车配件采购平台</p>
          <p>在线采购 | 实时库存 | 极速发货 | 正品保证</p>
          <div class="logo-button">
            <button>
              <a href="#a01" style="text-decoration: none;color: #fff;"><img src="../assets/apply-for.png">
              立即申请</a>
            </button>
            <button @mousemove="appear=2" @mouseout="appear=null">
              <span>登录平台</span>
              <img src="../assets/right_arrow.png">
              <div class="QR-code-box-two" v-show="appear==2">
                <img src="../assets/QR-code.png">
                <span>扫码打开</span>
              </div>
            </button>

          </div>
        </div>
        <div class="logo-right">
          <img class="controlplatform" src="../assets/controlplatform.png">
        </div>
      </div>
    </div>
    <!--    供应链-->
    <div class="supply">
      <div class="supply-imgs">
        <div>
          <div>
            <img src="../assets/handshake.png">
          </div>
          <div>
            <p>直营供应链</p>
            <p>线上报价下单，简单易用，零成本上手</p>
          </div>
        </div>
        <div>
          <div>
            <img src="../assets/salable-product.png">
          </div>
          <div>
            <p>正品保证</p>
            <p>品牌厂家授权直供，100%品质保障</p>
          </div>
        </div>
        <div>
          <div>
            <img src="../assets/quickness.png">
          </div>
          <div>
            <p>急速发货</p>
            <p>多仓直发，24小时极速发货，物流信息实时可查</p>
          </div>
        </div>
      </div>
    </div>
    <!--    配件-->
    <div class="accessories">
      <img src="../assets/good-stuff.png">
      <div class="accessories-box">
        <div :style="{marginLeft:left + 'px'}" :class="{active:active == -1}" @mousemove="setActive(-1)" @mouseout="active=0">
          <img src="../assets/BBS-CHRII.png">
          <p>BBS-CHR II</p>
          <p>暂无报价</p>
        </div>
        <div  :class="{active:active==1}" @mousemove="setActive(1)" @mouseout="active=0">
          <img src="../assets/BBS-SR.png">
          <p>BBS-SR</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active==2}" @mousemove="setActive(2)" @mouseout="active=0">
          <img src="../assets/BBS-SuperRS.png">
          <p>BBS-Super RS</p>
          <p>￥45000起</p>
        </div>
        <div :class="{active:active==3}" @mousemove="setActive(3)" @mouseout="active=0">
          <img src="../assets/brembo.png">
          <p>brembo-高级陶瓷制动片</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active == 4}" @mousemove="setActive(4)" @mouseout="active=0">
          <img src="../assets/Brembo-Xtra.png">
          <p>Brembo-Xtra</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active == 5}" @mousemove="setActive(5)" @mouseout="active=0">
          <img src="../assets/ENDLESS_EC670.png">
          <p>ENDLESS_EC670</p>
          <p>￥13000起</p>
        </div>
        <div :class="{active:active == 6}" @mousemove="setActive(6)" @mouseout="active=0">
          <img src="../assets/KW_Clubsport_3way.png">
          <p>KW_Clubsport_3way</p>
          <p>￥30000起</p>
        </div>
        <div :class="{active:active == 7}" @mousemove="setActive(7)" @mouseout="active=0">
          <img src="../assets/KW_Variant1.png">
          <p>KW_Variant 1</p>
          <p>￥13000起</p>
        </div>
        <div :class="{active:active == 8}" @mousemove="setActive(8)" @mouseout="active=0">
          <img src="../assets/Repose.png">
          <p>Repose-四出中尾段智能阀门排气</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active == 9}" @mousemove="setActive(9)" @mouseout="active=0">
          <img src="../assets/ROTIFORM-BLQ.png">
          <p>ROTIFORM-BLQ</p>
          <p>￥2250起</p>
        </div>
        <div :class="{active:active == 10}" @mousemove="setActive(10)" @mouseout="active=0">
          <img src="../assets/ROTIFORM-DTM.png">
          <p>ROTIFORM-DTM</p>
          <p>￥2130起</p>
        </div>
        <div :class="{active:active == 11}" @mousemove="setActive(11)" @mouseout="active=0">
          <img src="../assets/ROTIFORM-OZR.png">
          <p>ROTIFORM-OZR</p>
          <p>￥2700起</p>
        </div>
        <div :class="{active:active == 12}" @mousemove="setActive(12)" @mouseout="active=0">
          <img src="../assets/BBS-CHRII.png">
          <p>BBS-CHR II</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active == 13}" @mousemove="setActive(13)" @mouseout="active=0">
          <img src="../assets/BBS-SR.png">
          <p>BBS-SR</p>
          <p>暂无报价</p>
        </div>
        <div :class="{active:active == 14}" @mousemove="setActive(14)" @mouseout="active=0">
          <img src="../assets/BBS-SuperRS.png">
          <p>BBS-Super RS</p>
          <p>￥45000起</p>
        </div>
        <div :class="{active:active == 15}" @mousemove="setActive(14)" @mouseout="active=0">
          <img src="../assets/brembo.png">
          <p>brembo-高级陶瓷制动片</p>
          <p>暂无报价</p>
        </div>
      </div>
      <div class="accessories-arrows">
        <div @click="setNum(false)">
          <img src="../assets/list_left_arrow@2x.png">
        </div>
        <div @click="setNum(true)">
          <img src="../assets/list_right_arrow@2x.png">
        </div>
      </div>
    </div>
    <!--    合作伙伴-->
    <div class="partner" >
      <img src="../assets/cooperation.png">
      <div class="accessories-box" id="a01">
        <div>
          <img src="../assets/1BBS.png">
        </div>
        <div>
          <img src="../assets/2ENDLESS.png">
        </div>
        <div>
          <img src="../assets/3KW.png">
        </div>
        <div>
          <img src="../assets/5Brembo.png">
        </div>
        <div>
          <img src="../assets/6APRACING.png">
        </div>
        <div>
          <img src="../assets/7Velocita.png">
        </div>
        <div>
          <img src="../assets/8Repose.png">
        </div>
        <div>
          <img src="../assets/9BILSTEIN.png">
        </div>
      </div>
      <p>
        更多品牌正在入驻
      </p>
    </div>
    <!--    申请-->
    <div class="apply-for">
      <p>免费使用</p>
      <p>仅需一步，开启线上采购模式</p>
      <div class="apply-for-input">
        <input placeholder="请输入你的手机号码" v-model="mobile" />
        <button @click="decide"><span>立即申请</span></button>
      </div>
    </div>
    <!--    介绍-->
    <div class="introduce">
      <div class="introduce-top">
        <div class="description">
          <img src="../assets/logotitle.png">
          <div>一个专注于改装车后市场服务升级的团队，具备丰富的汽车改装行业背景，对行业痛点、优化方向及实现路径具有深刻认识。通过打造一站式采购、销售、技术信息平台重塑汽车后市场服务及配件行业业态；通过输出专业的汽车改装文化以及研发汽车配件、房车露营衍生品扩大品牌影响力。</div>
        </div>
        <div class="introduce-classification-box">
          <p>联系我们</p>
          <div class="introduce-classification">
            <img src="../assets/phone.png">
            <span>陈先生-13123377878</span>
          </div>
          <div class="introduce-classification">
            <img src="../assets/E-mail.png">
            <span>chenzhihe@jiihee.com</span>
          </div>
          <div class="introduce-classification">
            <img src="../assets/site.png">
            <span>厦门市思明区软件园二期望海路23号楼之一</span>
          </div>
        </div>
        <div class="QR-code-box">
          <img src="../assets/QR-code.png">
          <p>微信扫码添加</p>
        </div>
      </div>
    </div>
    <div class="introduce-bottom">
      <p>版权：厦门厚思想工业设计有限公司</p>
      <p>备案：闽ICP备2021009637号-2</p>
    </div>
<!--    提示框-->
    <div class="ts" v-if="show">
      <div>
        <p>手机号格式错误,请重新输入</p>
        <button @click="affirm">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import {saveBusinessChance} from "../service/mobile";
export default {
  name: 'Home',
  data(){
    return{
      img:require('@/assets/logo.png'),
      mobile:null,
      appear:null,
      num:0,
      left:null,
      demoTimer:null,
      active:0,
      show:false,
    }
  },
  created() {
    // this.setLeft()
  },
  methods:{
    affirm(){
      this.show = false;
      this.mobile = ''
    },
    setNum(val){
      if (val){
        this.num = this.num +215;
        if (this.num + 215 >2700 ){
          this.num = 0;
        }
        this.left = this.num * -1;
      }else{
        this.num = this.num - 215;
        if (this.num - 215 < 0){
          this.num = 2380
        }
        this.left = this.num* -1
      }
    },
    async decide(){
      console.log(this.mobile * 1)
      let num = this.mobile;
      let val = /^1[0-9]{10}$/;
      if (val.test(num)){
        let parmas={
          telephone:this.mobile,
          source:'pc端官网'
        }
        let res =  await saveBusinessChance(parmas);
        if (res.data.code == 0 ){
          this.$router.push({path:'succeed'})
        }else{
          Dialog({ message: `${res.data.message}` });
        }
      }else{
        this.show = true
      }
    }
  }
}
</script>
<style lang="less" >
*{
  margin: 0;
  padding: 0;
}
@import "../common/mixin";
.ts{
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0,0,0,0.3);
  &>div{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    background: #FFFFFF;
    width: 400px;
    height: 200px;
    border-radius: 30px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    p{
      height: 100px;
      line-height: 100px;
      font-size: 22px;
      font-weight: 500;
      border-bottom: 1px solid #CCCCCC;
    }
    button{
      width: 200px;
      height: 70px;
      font-size: 22px;
      border: none;
      background: none;
    }
  }
}
.logo{
  width: 100%;
  margin: 0 auto;
  background: url("../assets/background.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  padding-top: 75px;
  padding-bottom: 53px;
  box-sizing: border-box;
  .logo-box{
    display: flex;
    width: 1440px;
    margin: 0 auto;
    .logo-left{
      width: 540px;
      margin-left: 100px;
      margin-right: 109px;
      p:nth-of-type(1){
        height: 75px;
        font-size: 53px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #09172F;
        line-height: 75px;
      }
      p:nth-of-type(2){
        margin-top: 5px;
        width: 535px;
        font-size: 23px;
        font-weight: 400;
        color: #595959;
        line-height: 35px;
      }
      .logo-button{
        margin-top:77px;
        button{
          width: 184px;
          height: 53px;
          background: #527E69;
          border-radius: 25px;
          border: none;
          margin-right: 12px;
          img{
            width: 21px;
            vertical-align: middle;
          }
          span{
            color: #FFFFFF;
            text-decoration: none;
            vertical-align: middle;
          }
          a{
            vertical-align: middle;
            display:inline-block;
            width: 100%;
            height: 53px;
            line-height: 53px;
          }
        }
        button:first-child{
          margin-right: 19px;
          img{
            margin-right: 11px;
            vertical-align: middle;
          }
        }
        button:nth-of-type(2){
          color: #FFFFFF;
          position: relative;
          img{
            margin-left: 15px;
            vertical-align: middle;
          }
        }
      }
    }
    .logo-right{
      img{
        width: 709px;
        height: 347px;
      }
    }
  }
}
.box{
  width: 1440px;
  margin: 0 auto;
}
.logotitle{
  width:284px;
  height:43px;
  vertical-align: middle;
}
.logotitle-box{
  width: 1440px;
  padding: 19px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  button{
    width: 147px;
    height: 45px;
    background: #527E69;
    border-radius: 23px;
    font-size: 19px;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    vertical-align: middle;
    position: relative;
    img{
      width: 19px;
      height: 19px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
.supply{
  width: 1440px;
  margin: 0 auto;
  .supply-imgs{
    width: 427px * 3;
    margin: 0 auto;
    margin-top: 103px;
    display: flex;
    text-align: center;
    &>div{
      width: 427px;
      height: 372px;
      padding:0 13px ;
      padding-top: 52px;
      text-align: center;
      img{
        width: 96px;
        height: 96px;
        margin-bottom: 29px;
      }
      p:nth-of-type(1){
        height: 56px;
        font-size: 40px;
        font-weight: 500;
        color: #09172F;
        line-height: 56px;
        margin-bottom: 23px;
      }
      p:nth-of-type(2){
        height: 75px;
        font-size: 27px;
        font-weight: 400;
        color: #858585;
      }
    }
  }
}
.accessories{
  padding-top: 21px;
  text-align: center;
  overflow: hidden;
  width: 1440px;
  margin: 0 auto;
  padding-bottom: 104px;
  position: relative;
  img:nth-of-type(1){
    width: 613px;
    height: 157px;
  }
  .accessories-arrows{
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    display: flex;
    div:nth-of-type(1){
      width: 53px;
      height: 120px;
      text-align: center;
      align-items: center;
      background: #EAF5F0;
      position: absolute;
      border-radius: 27px;
      left: 0;
      top: 50%;
      img{
        width: 17px;
        height: 27px;
        margin-top: 80%;
      }
    }
    div:nth-of-type(2){
      width: 53px;
      height: 120px;
      text-align: center;
      align-items: center;
      background: #EAF5F0;
      border-radius: 27px;
      position: absolute;
      right: 0;
      top: 50%;
      img{
        width: 17px;
        height: 27px;
        margin-top: 80%;

      }
    }
  }
  .accessories-box{
    display: flex;
    width: 1220px;
    height: 409px;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 5px;
    &>div{
      width: 20%;
      padding: 28px 21px 0;
      margin: 8px;
      text-align: center;
      img{
        width: 250px;
        height: 250px;
      }
      p:nth-of-type(1){
        margin: 13px 0;
        height: 29px;
        font-size: 21px;
        font-weight: 500;
        color: #09172F;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      p:nth-of-type(2){
        height: 32px;
        font-size: 23px;
        font-weight: 600;
        color: #66A386;
        line-height: 32px;
      }
    }
  }
}
.partner{
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(180deg, #F8FBFD 0%, #FBFBFB 100%);
  img:nth-of-type(1){
    margin-top: 79px;
    width: 613px;
    height: 157px;
  }
  .accessories-box{
    display: flex;
    padding:0 265px;
    padding-top: 23px;
    justify-content: space-between;
    width: 1440px;
    margin: 0 auto;
    &>div{
      text-align: center;
      margin-right: 32px;
      img{
        width: 133px;
        height: 133px;
      }
      img:nth-of-type(8n){
        margin-right: 0;
      }
    }
  }
  p{
    margin: 0 auto;
    margin-top: 41px;
    padding-bottom: 68px;
    font-size: 19px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #AAB6C8;
  }
}
.apply-for{
  width: 100%;
  margin: 0 auto;
  background:url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 672px;
  text-align: center;
  p:nth-of-type(1){
    padding-top: 151px;
    font-size: 51px;
    font-weight: 600;
    color: #09172F;
    margin-bottom: 5px;
  }
  p:nth-of-type(2){
    font-size: 35px;
    font-weight: 400;
    color: #527E69;
    margin-bottom: 60px;
  }
  .apply-for-input{
    width: 800px;
    height: 107px;
    margin: 0 auto;
    display: flex;
    border: 2px solid #527E69;
    border-radius: 53px;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.11);
    input{
      border: 0;
      width: calc(800px - 227px);
      border-radius: 53px;
      outline: none;
      padding-left: 45px;
      align-items: center;
      font-size: 35px;
    }
    button{
      width: 227px;
      background: #527E69;
      border-radius: 53px;
      border: none;
      margin: 8px 8px 8px 0;
      padding: 21px 43px 20px;
      span{
        height: 33px;
        font-size: 23px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
      }
    }
  }
}
.introduce{
  margin: 0 auto;
  padding-top: 147px;
  .introduce-top{
    width: 1440px;
    display: flex;
    padding:  0 100px;
    margin: 0 auto;
    .description{
      width: 403px;
      margin-right: 123px;
      &>div{
        font-size: 19px;
        font-weight: 400;
        color: #9BA6BA;
        line-height: 27px;
      }
      img{
        height: 41px;
        margin-bottom: 29px;
      }
    }
    .introduce-classification-box{
      margin-right: 201px;
      p:nth-of-type(1){
        font-size: 21px;
        font-weight: 500;
        color: #09172F;
        line-height: 27px;
        margin-bottom: 48px;
      }
      .introduce-classification{
        img{
          width: 16px;
          margin-right: 16px;
        }
        span{
          height: 21px;
          font-size: 19px;
          font-weight: 400;
          color: #9BA6BA;
          line-height: 21px;
        }
      }
      .introduce-classification:nth-of-type(2){
        margin: 16px 0 19px 0;
      }
    }
    .QR-code-box{
      width: 206px;
      height: 224px;
      padding: 19px 16px 0 19px;
      box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.11);
      text-align: center;
      img{
        width: 171px;
        height: 171px;
      }
      p:last-child{
        font-size: 15px;
        font-weight: 400;
        color: #78859D;
      }
    }
  }
}
.introduce-bottom{
  width: 1440px;
  margin: 0 auto;
  display: flex;
  margin-top: 83px;
  font-size: 19px;
  font-weight: 400;
  color: #9BA6BA;
  line-height: 27px;
  //padding-left: 100px;
  padding-bottom: 81px;
  p:nth-of-type(2){
    margin-left: 50px;
  }
}
.QR-code-box-one{
  position: absolute;
  top: 100%;
  left: -25%;
  width: 206px;
  height: 214px;
  padding-top: 25px !important;
  box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.11);
  text-align: center;
  img{
    width: 164px !important;
    height: 164px !important;
  }
  p:last-child{
    margin-top: 14px;
    font-size: 15px;
    font-weight: 400;
    color: #78859D;
  }
}
.QR-code-box-two{
  position: absolute;
  top: 100%;
  left: -5%;
  width: 206px;
  height: 214px;
  padding-top: 28px;
  box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.11);
  text-align: center;
  img{
    width: 164px !important;
    height: 164px !important;
    margin: 0 !important;
  }
  span{
    margin-top: 14px;
    font-size: 15px;
    font-weight: 400;
    color: #78859D !important;
    display: block;
  }
}
.active{
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.12);
}
</style>
